
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  padding: 8px
  background-color: $color-bronze-dark
  overflow: auto
  box-sizing: border-box

article::v-deep
  .v-application, .v-application--wrap
    font-family: $font-main
    display: block
    min-height: auto
  .theme--light.v-application
    background-color: transparent
  .container
    box-sizing: border-box

.inner
  background-color: white
  border-radius: 8px
  overflow: auto
  height: 100%
  display: flex
  align-items: center
  flex-direction: column

.center
  max-width: 400px
  background-color: $color-beige
  border-radius: 8px
  padding: 16px 32px
  margin-top: 120px
  margin-bottom: 80px
  h1, p
    color: black
  +bpw(md)
    max-width: 600px
  +bpw(lg)
    margin-top: 210px
    max-width: 720px
  +bpw(xl)
    padding-top: 48px

.form
  display: flex
  flex-direction: row

.form-content
  +bpw(xl)
    margin-top: 32px

.fields
  padding-top: 14px
  width: 100%

.text
  text-align: center
  h1
    margin-bottom: 24px
  p
    font-size: 14px
    padding: 0 32px
    margin: 12px 0
  +bpw(lg)
    p
      font-size: 18px

.actions
  text-align: right
  padding-bottom: 16px
  button
    margin-left: 16px

.ending
  background-color: white
  padding: 16px
  border-radius: 8px
  &.is-success
    h2, p
      color: $color-green
  &.is-error
    h2, p
      color: $color-red
  h2
    margin-bottom: 12px
